import React, { useEffect, useState, } from 'react';
import './App.css';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import theme from '@styles/theme';
import BrowserNotSupported from '@pages/BrowserNotSupported';
import GeneralProvider from '@context/GeneralContext';
import Routes from './Routes';
import {
    checkMode,
    initContextSentry,
    setPostMessageServer
} from './setup';

export default function InitiateRoutes() {
    const [validBrowser, setValidBrowser] = useState(true);
    let longURL;
    const checkBrowserVersion = () => {
        let minimalChromeVersion = 73;
        // TODO
        // let minimalSafariVersion = 0;
        let browserInfo = navigator.userAgent;
        let blockedBrowser = false;

        // ANDROID
        // if (/Android/i.test(browserInfo)) {
        //   let chromeVersion = /Chrome\/(\d+)/.exec(browserInfo)[1];
        //   if (chromeVersion < minimalChromeVersion) {
        //     blockedBrowser = true;
        //   }
        // }

        // IOS
        // if (/iPad|iPhone|iPod/.test(browserInfo) && !window.MSStream) {
        //   // TODO
        //   let safariVersion = /Safari\/(\d+)/.exec(browserInfo)[1];
        //   if (safariVersion < minimalSafariVersion) {
        //     blockedBrowser = true;
        //   }
        // }

        let chromeVersion = /Chrome\/(\d+)/.exec(browserInfo);
        if (chromeVersion && chromeVersion[1] < minimalChromeVersion) {
            blockedBrowser = true;
        } else {
            setValidBrowser(!blockedBrowser);
        }

        if (blockedBrowser) {
            // tidak bisa pakai path url React (/browser-not-supported, etc)
            // karena diluar scope Routes
            // sedangkan diinject ke Routes pun juga error, karena belum fetch config, error theme dependency ke response config
            // MVP pakai if else dengan raw react js biasa
            setValidBrowser(!blockedBrowser);

        }
    };

    const checkObjectFromEntries = () => {
        try {
            Object.fromEntries([]);
        } catch (error) {
            setValidBrowser(false)
        }
    };

    useEffect(() => {
        setPostMessageServer();

        checkMode()

        initContextSentry(longURL);
        checkBrowserVersion();
        checkObjectFromEntries();
    }, [])

    return (
        <div>
            {
                validBrowser ? (
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Router>
                            <GeneralProvider>
                                <Routes />
                            </GeneralProvider>
                        </Router>
                    </ThemeProvider>
                ) : (
                    <BrowserNotSupported />
                )
            }

        </div>
    )
}
